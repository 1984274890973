import { Model } from '@vuex-orm/core'

export default class EngagedUser extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'engaged_users'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.number(0),
            new_user: this.boolean(false),
            engaged_user_id: this.attr(''),
            current_engaging_part_event_id: this.attr('')
        }
    }
}