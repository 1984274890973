<template>
  <div
    class="syncc-widget__zoom-content--storyicon-4aqmLwncEr"
    v-on:mouseover="mouseOverWidgetIcon"
    v-on:mouseleave="mouseLeaveWidgetIcon"
  >
    <div ref="videoIconBtn" style="display: none"></div>
    <div ref="loadingImgPreLoad" style="display: none">
      <img :src="loader" width="1" height="1" />
    </div>
    <transition name="bottom">
      <div
        v-show="iconShow"
        :style="styles.iconContainerStyle"
        :class="{
          'syncc-widget__icon-on-scroling-37MMepod': this.userOnTopScreen,
          'syncc-widget__icon-on-scroling-j4k2xDEg': !this.userOnTopScreen,
          'syncc-widget__icon_hover-Me92Ndxs2': this.isMouseOverWidgetIcon,
          'syncc-widget__icon_hover-leave-k31lAdn5o':
            this.isMouseLeaveOverWidgetIcon,
        }"
        style="
          animation-duration: 0.6s !important;
          animation-timing-function: ease-out !important;
          animation-delay: 0s !important;
          animation-iteration-count: 1 !important;
          animation-direction: normal !important;
          animation-fill-mode: none !important;
          animation-play-state: running !important;
        "
      >
        <img
          v-if="iconContentTag == ('img' || 'gif')"
          id="video-icon"
          :style="styles.iconStyle"
          :src="iconSrc"
          @load="imageLoaded"
          v-on:click.prevent="conversionVideo"
        />
        <span :class="notificationBadgeClass"></span>
        <mute
          v-if="iconShape == 'RECTANGLE'"
          :size="26"
          :color="playIconColor"
          class="syncc-widget__welcome-icon-mute-93nxKnwl"
          v-on:click.prevent="conversionVideo"
        />
        <play-original
          v-if="iconShape == 'RECTANGLE'"
          :size="30"
          :color="playIconColor"
          class="syncc-widget__welcome-icon-play-video-btn-nl39Nfj2"
          v-on:click.prevent="conversionVideo"
        />

        <close-btn
          v-if="
            closeBtnShow &&
            notificationBadgeClass ==
              'syncc-widget__notification-badge-none-6L2doxp'
          "
          class="syncc-widget__welcome-icon-close-btn-LdeN298b"
          v-on:close-el="closeIcon"
        />
      </div>
    </transition>
    <transition name="bottom">
      <text-bubble
        v-show="iconShow && userOnTopScreen"
        :textBubbleContent="textBubbleContent"
        :textBubbleStyle="styles.textBubbleStyle"
        v-on:conversion-video="conversionVideo"
        style="
          animation-duration: 0.6s !important;
          animation-timing-function: ease-out !important;
          animation-delay: 0s !important;
          animation-iteration-count: 1 !important;
          animation-direction: normal !important;
          animation-fill-mode: none !important;
          animation-play-state: running !important;
        "
      ></text-bubble>
    </transition>
    <div id="synccPreloadVideoContainer" style="display: none">
      <preload-first-video
        v-if="iconShow"
        :playbackId="preloadVideo.playbackId"
        :jwt="preloadVideo.jwt"
      ></preload-first-video>
    </div>
  </div>
</template>

<script>
import Story from "../models/Story";
import Config from "../models/Config";
import Customization from "../models/Customization";
import Part from "../models/Part";
import UserDetail from "../models/UserDetail";
import EngagedUser from "../models/EngagedUser";
import UserEngagement from "../models/UserEngagement";
import Video from "../models/Video";

import CloseBtn from "../components/UI/CloseBtn.vue";
import Mute from "../components/UI/icons/svg/Mute.vue";
import PlayOriginal from "../components/UI/icons/svg/PlayOriginal.vue";
import TextBubble from "../components/UI/TextBubble.vue";
import PreloadFirstVideo from "../components/UI/PreloadFirstVideo.vue";

import store from "../store/index";

export default {
  components: {
    CloseBtn,
    Mute,
    PlayOriginal,
    TextBubble,
    PreloadFirstVideo,
  },
  props: {},
  data() {
    return {
      iconSrc: "",
      iconShow: false,
      iconShape: "CIRCLE",
      playIconColor: "#fff",
      iconContentTag: "img",
      videoIconPlaybackId: "",
      closeBtnShow: true,
      isMouseOverWidgetIcon: false,
      isMouseLeaveOverWidgetIcon: false,
      textBubbleContent: "",
      notificationBadgeClass: "syncc-widget__notification-badge-none-6L2doxp",
      styles: {
        textBubbleStyle: {},
        iconContainerStyle: {},
        iconStyle: {},
      },
      preloadVideo: {
        playbackId: "",
        jwt: "",
      },
      loader: "",
      userOnTopScreen: true,
      userDevice: { windowTop: 0, vhInPx: 0, documentHeightInPx: 0 },
      teamSlug: String,
    };
  },
  mounted() {
    this.$refs.videoIconBtn.click();
    window.addEventListener("scroll", this.onScroll, true);
    this.userDevice.vhInPx = document.documentElement.clientHeight;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  created() {
    const video = Video.all()[0];
    let iconPlaybackId = video.mux_playback_id;
    let iconImgJWT = video.mux_jwt_for_img;
    let iconGifJWT = video.mux_jwt_for_gif;
    let config = Config.all()[0];
    if (config.icon_loop) {
      this.iconContentTag = "img";
      this.iconSrc =
        "https://image.mux.com/" +
        iconPlaybackId +
        "/animated.gif?token=" +
        iconGifJWT;
    } else {
      this.iconContentTag = "img";
      this.iconSrc =
        "https://image.mux.com/" + iconPlaybackId + "?token=" + iconImgJWT;
    }
    this.preloadVideo.playbackId = video.mux_playback_id;
    this.preloadVideo.jwt = video.mux_jwt;

    let story = Story.all()[0];
    this.teamSlug = story.team_slug;
    if (story.thumbnail) {
      this.iconSrc = story.thumbnail;
    }

    if (config.allow_remove) {
      this.closeBtnShow = true;
    } else {
      this.closeBtnShow = false;
    }

    let additionalMarginBottom = 0;

    if (Object.keys(config.additional_margin_bottoms).length > 1) {
      const userDetail = UserDetail.all()[0];
      if (userDetail.platform == "mobile") {
        additionalMarginBottom = config.additional_margin_bottoms.mobile;
      } else {
        additionalMarginBottom = config.additional_margin_bottoms.desktop;
      }
    } else {
      additionalMarginBottom = config.additional_margin_bottom;
    }

    let iconPositionMarginBottomInPixel = 20 + additionalMarginBottom;
    let textBubbleMarginBottomInPixel = 10 + additionalMarginBottom;

    let customization = Customization.all()[0];

    const iconSizeCircle =
      customization.icon_size == "SMALL"
        ? { height: "130px", width: "130px" }
        : customization.icon_size == "MEDIUM"
        ? { height: "155px", width: "155px" }
        : { height: "200px", width: "200px" };

    const iconSizeRectangle =
      customization.icon_size == "SMALL"
        ? { height: "130px", width: "231px" }
        : customization.icon_size == "MEDIUM"
        ? { height: "155px", width: "276px" }
        : { height: "200px", width: "356px" };

    this.iconShape = customization.icon_shape;

    if (
      customization.icon_shape !== "CIRCLE" &&
      customization.video_orientation == "VERTICAL_VIDEO"
    ) {
      this.swapObj(iconSizeRectangle, "height", "width");
    }

    const textBubblePositionWhenIconCircleAndPositionLeft =
      customization.icon_size == "SMALL"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            left: "170px",
            padding: "0.93em 1.23em",
            fontSize: "0.9em",
          }
        : customization.icon_size == "MEDIUM"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            left: "200px",
            padding: "1.02em 1.34em",
            fontSize: "1.05em",
          }
        : {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            left: "230px",
            padding: "1.125em 1.5em",
            fontSize: "1.25em",
          };

    const textBubblePositionWhenIconCircleAndPositionRight =
      customization.icon_size == "SMALL"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            right: "170px",
            padding: "0.93em 1.23em",
            fontSize: "0.9em",
          }
        : customization.icon_size == "MEDIUM"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            right: "200px",
            padding: "1.02em 1.34em",
            fontSize: "1.05em",
          }
        : {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            right: "230px",
            padding: "1.125em 1.5em",
            fontSize: "1.25em",
          };

    const textBubblePositionWhenIconRectangleAndPositionLeft =
      customization.icon_size == "SMALL"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            left:
              customization.video_orientation == "VERTICAL_VIDEO"
                ? "180px"
                : "277px",
            padding: "0.93em 1.23em",
            fontSize: "0.9em",
          }
        : customization.icon_size == "MEDIUM"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            left:
              customization.video_orientation == "VERTICAL_VIDEO"
                ? "210px"
                : "315px",
            padding: "1.02em 1.34em",
            fontSize: "1.05em",
          }
        : {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            left:
              customization.video_orientation == "VERTICAL_VIDEO"
                ? "255px"
                : "396px",
            padding: "1.125em 1.5em",
            fontSize: "1.25em",
          };

    const textBubblePositionWhenIconRectangleAndPositionRight =
      customization.icon_size == "SMALL"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            right:
              customization.video_orientation == "VERTICAL_VIDEO"
                ? "180px"
                : "277px",
            padding: "0.93em 1.23em",
            fontSize: "0.9em",
          }
        : customization.icon_size == "MEDIUM"
        ? {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            right:
              customization.video_orientation == "VERTICAL_VIDEO"
                ? "210px"
                : "315px",
            padding: "1.02em 1.34em",
            fontSize: "1.05em",
          }
        : {
            bottom: String(textBubbleMarginBottomInPixel) + "px",
            right:
              customization.video_orientation == "VERTICAL_VIDEO"
                ? "255px"
                : "396px",
            padding: "1.125em 1.5em",
            fontSize: "1.25em",
          };

    this.textBubbleContent = customization.text_bubble;
    this.styles.textBubbleStyle =
      customization.icon_position == "LEFT"
        ? customization.icon_shape == "CIRCLE"
          ? textBubblePositionWhenIconCircleAndPositionLeft
          : textBubblePositionWhenIconRectangleAndPositionLeft
        : customization.icon_shape == "CIRCLE"
        ? textBubblePositionWhenIconCircleAndPositionRight
        : textBubblePositionWhenIconRectangleAndPositionRight;

    const userEngagement = UserEngagement.all()[0];
    if (userEngagement.play) {
      this.notificationBadgeClass =
        "syncc-widget__notification-badge-none-6L2doxp";
    } else {
      this.notificationBadgeClass = customization.notification_badge
        ? customization.icon_shape == "CIRCLE"
          ? "syncc-widget__notification-badge-circle-6L2doxp"
          : "syncc-widget__notification-badge-rectangle-6L2doxp"
        : "syncc-widget__notification-badge-none-6L2doxp";
    }

    const iconPosition =
      customization.icon_position == "LEFT"
        ? {
            bottom: String(iconPositionMarginBottomInPixel) + "px",
            left: "20px",
          }
        : {
            bottom: String(iconPositionMarginBottomInPixel) + "px",
            right: "20px",
          };
    const iconShape =
      customization.icon_shape == "CIRCLE" ? iconSizeCircle : iconSizeRectangle;

    const iconRadius = customization.icon_shape == "CIRCLE" ? "50%" : "15px";

    const defaultBorderColor = `-webkit-linear-gradient(left top, #b827fc 0%, #2c90fc 30%, #b8fd33 50%, #fec837 60%, #fd1892 100%)`;
    const iconBorderColor =
      customization.icon_border == "DEFAULT"
        ? defaultBorderColor
        : customization.icon_border == "CUSTOM"
        ? customization.theme_color
        : "none";
    this.playIconColor = customization.theme_color
      ? customization.theme_color
      : defaultBorderColor;

    this.styles.iconContainerStyle = {
      ...iconPosition,
      display: "block",
      position: "fixed",
      "-webkit-user-select": "none",
      margin: "auto",
      "z-index": "999 !important",
    };

    this.styles.iconStyle = {
      ...iconShape,
      transition: "background-color 300ms; border-radius 300ms",
      objectFit: "cover",
      borderRadius: iconRadius,
      zIndex: "997 !important",
      padding: "6px",
      cursor: "pointer",
      background: iconBorderColor,
    };

    const isProduction = process.env.NODE_ENV === "production";
    if (isProduction) {
      this.loader = "https://syncc-widget.web.app/img/loader_black.gif";
    } else {
      this.loader = require("@/assets/gif/loader_black.gif");
    }
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    swapObj(obj, key1, key2) {
      [obj[key1], obj[key2]] = [obj[key2], obj[key1]];
    },
    onScroll: function () {
      this.userDevice.windowTop = window.top.scrollY;
      if (this.userDevice.vhInPx / 1.5 < this.userDevice.windowTop) {
        this.userOnTopScreen = false;
      } else {
        this.userOnTopScreen = true;
      }
    },
    mouseOverWidgetIcon() {
      this.isMouseOverWidgetIcon = true;
      this.isMouseLeaveOverWidgetIcon = false;
    },
    mouseLeaveWidgetIcon() {
      this.isMouseOverWidgetIcon = false;
      this.isMouseLeaveOverWidgetIcon = true;
      setTimeout(() => {
        this.isMouseLeaveOverWidgetIcon = false;
      }, 1000);
    },
    async conversionVideo() {
      const initialPartId = Part.all()[0].part_id;
      this.$router.push({
        name: "story-videos",
        params: { id: initialPartId },
        query: { initial: true },
      });

      let engagedUser = EngagedUser.all()[0];
      if (!engagedUser.engaged_user_id) {
        const { newUser, engagedUserId } = await this.getEngageUser(
          this.teamSlug
        );
        EngagedUser.update({
          where: "0",
          data: {
            newUser: newUser,
            engagedUserId: engagedUserId,
          },
        });
        let engagement = UserEngagement.all()[0];
        this.sendUpdateEngagementWithEngagedUser(
          engagement.engagement_id,
          engagedUserId
        );
      }
    },
    imageLoaded() {
      this.iconShow = true;
    },
    closeIcon() {
      this.iconShow = false;
    },
    disposeIcon() {
      let iconEl = document.getElementById("video-icon");
      iconEl.removeAttribute("src");
      iconEl.load();
    },
    sendUpdateEngagementWithEngagedUser(engagementId, engagedUserId) {
      const payload = {
        engagementId: engagementId,
        engagedUserId: engagedUserId,
      };
      store.dispatch("updateEngagementWithEngagedUser", payload);
    },
    resolveUserFromViewerDataArr(viewerData, teamSlug) {
      if (viewerData) {
        for (var i = 0; i < viewerData.length; i++) {
          if (viewerData[i].team_slug == teamSlug) {
            return {
              isExistingEngagedUser: true,
              engagedUserId: viewerData[i].viewer_id,
            };
          }
          if (
            i === viewerData.length - 1 &&
            viewerData[i].team_slug !== teamSlug
          ) {
            return {
              isExistingEngagedUser: false,
              engagedUserId: "",
            };
          }
        }
      } else {
        return {
          isExistingEngagedUser: false,
          engagedUserId: "",
        };
      }
    },
    async getEngageUser(teamSlug) {
      var viewerDataArr = JSON.parse(localStorage.getItem("synccViewerData"));
      const { isExistingEngagedUser, engagedUserId } =
        this.resolveUserFromViewerDataArr(viewerDataArr, teamSlug);

      if (isExistingEngagedUser) {
        return {
          newUser: false,
          engagedUserId: engagedUserId,
        };
      } else {
        const payload = {
          teamSlug: teamSlug,
        };
        var _engagedUserId = "";
        await store
          .dispatch("createAnonymousEngagedUser", payload)
          .then((response) => {
            if (!response) {
              return {
                newUser: false,
                engagedUserId: null,
              };
            }
            _engagedUserId = response.data.engaged_user_id;
            if (viewerDataArr) {
              viewerDataArr.push({
                team_slug: teamSlug,
                viewer_id: _engagedUserId,
              });
              localStorage.setItem(
                "synccViewerData",
                JSON.stringify(viewerDataArr)
              );
            } else {
              const newViewerDataArr = [
                {
                  team_slug: teamSlug,
                  viewer_id: _engagedUserId,
                },
              ];
              localStorage.setItem(
                "synccViewerData",
                JSON.stringify(newViewerDataArr)
              );
            }
          });
        return {
          newUser: true,
          engagedUserId: _engagedUserId,
        };
      }
    },
  },
};
</script>

<style scoped>
.syncc-widget__welcome-icon-close-btn-LdeN298b {
  position: absolute;
  top: 5px !important;
  right: 4px !important;
  z-index: 997 !important;
  cursor: pointer;
}

.syncc-widget__notification-badge-circle-6L2doxp {
  position: absolute;
  top: 7px !important;
  right: 7px !important;
  z-index: 998 !important;
  padding: 12px 12px;
  border-radius: 50%;
  background: #ff4c51;
  color: white;
}

.syncc-widget__notification-badge-rectangle-6L2doxp {
  position: absolute;
  top: -5px !important;
  right: -4px !important;
  z-index: 998 !important;
  padding: 12px 12px;
  border-radius: 50%;
  background: #ff4c51;
  color: white;
}

.syncc-widget__notification-badge-none-6L2doxp {
  display: none;
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.syncc-widget__zoom-content--storyicon-4aqmLwncEr {
  zoom: 100%;
}
@-moz-document url-prefix() {
  /* For firefox */
  .syncc-widget__zoom-content--storyicon-4aqmLwncEr {
    transform-origin: 0 0;
    transform: scale(1);
  }
}

@media screen and (max-width: 767px) {
  .syncc-widget__zoom-content--storyicon-4aqmLwncEr {
    zoom: 45%;
  }
  @-moz-document url-prefix() {
    /* For firefox */
    .syncc-widget__zoom-content--storyicon-4aqmLwncEr {
      transform-origin: 0 0;
      transform: scale(0.45);
    }
  }
}
</style>