<template>
  <div class="syncc-widget__chapter-layout-podT3Ruxd">
    <div
      v-for="part in partList"
      :key="part.part_id"
      class="syncc-widget__chapter-part-21Dcj4Vlero"
      @click.prevent="emitVideoCV(part.part_id)"
    >
      {{ part.title }}
      <span v-if="part.part_id == nowPlayingPart">
        <img
          style="
            width: 29px;
            height: 29px;
            display: inline-block;
            vertical-align: middle;
          "
          :src="loader"
        />
      </span>
    </div>
    <chapter-close-btn
      class="syncc-widget__video-close-btn-Ake8ck3"
      v-on:close-chapter="closeChapter"
    />
  </div>
</template>

<script>
import Part from "../../models/Part";
import UserEngagement from "../../models/UserEngagement";

import ChapterCloseBtn from "./ChapterCloseBtn.vue";

export default {
  components: {
    ChapterCloseBtn,
  },
  props: {
    nowPlayingPart: { type: String, required: true },
  },
  data: {
    partList: Array,
    nowPlayingPart: String,
    loader: String,
  },
  created() {
    this.partList = Part.all();

    const isProduction = process.env.NODE_ENV === "production";
    if (isProduction) {
      this.loader = "https://syncc-widget.web.app/img/loader_black.gif";
    } else {
      this.loader = require("@/assets/gif/loader_black.gif");
    }
  },
  mounted() {},
  methods: {
    emitVideoCV(partId) {
      this.$emit("on-process-update-watch-status");
      this.$router.push({
        name: "story-videos",
        params: { id: partId },
        query: { initial: false },
      });
    },
    closeChapter() {
      this.$emit("close-chapter");
    },
  },
};
</script>


<style scoped>
</style>
