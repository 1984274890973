import { render, staticRenderFns } from "./ChapterCloseBtn.vue?vue&type=template&id=b664f28c&scoped=true&"
import script from "./ChapterCloseBtn.vue?vue&type=script&lang=js&"
export * from "./ChapterCloseBtn.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ChapterCloseBtn.vue?vue&type=style&index=0&id=b664f28c&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b664f28c",
  null
  ,true
)

export default component.exports