var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"syncc-widget__interactive-video-container-ml238skcl",style:(_vm.videoOrientationStyle)},[_c('media-controller',{key:_vm.playbackId,staticClass:"syncc-widget__media-controller-Q18nSj38d"},[_c('div',{staticClass:"syncc-widget__gradient-top-9Ndo12djx"}),_c('mux-video',{class:{
        'mux-video__horizontal': _vm.isHorizontal,
        'mux-video__vertical': !_vm.isHorizontal,
      },attrs:{"slot":"media","id":"synccVideo","preload":"auto","playback-id":_vm.muxSrc,"metadata-video-title":"Sync widget video","metadata-viewer-user-id":"user-id-1234","stream-type":"on-demand","prefer-mse":"","autoplay":"","playsinline":"","muted":""},on:{"canplay":_vm.switchToVideoView,"timeupdate":_vm.emitTimeUpdate},slot:"media"}),_c('media-control-bar',{staticClass:"syncc-widget__media-controller-bar-cm98dowldss"},[_c('div',{staticClass:"disable-background"},[(!_vm.initialPart)?_c('button',{staticClass:"media-controller__custom-button",on:{"click":function($event){$event.preventDefault();return _vm.backToLastPart.apply(null, arguments)}}},[_c('svg',{staticStyle:{"enable-background":"new 0 0 430 430"},attrs:{"width":"21","height":"21","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","id":"Warstwa_3","x":"0px","y":"0px","viewBox":"0 0 430 430","xml:space":"preserve"}},[_c('path',{attrs:{"stroke":"#fff","stroke-width":"25","d":"M306.78,405.03c-1.66,0-3.31-0.63-4.58-1.9L118.65,219.58c-1.21-1.21-1.9-2.86-1.9-4.58  c0-1.72,0.68-3.36,1.9-4.58L302.2,26.87c2.53-2.53,6.63-2.53,9.16,0c2.53,2.53,2.53,6.63,0,9.16L132.38,215l178.97,178.98  c2.53,2.53,2.53,6.63,0,9.16C310.09,404.39,308.43,405.03,306.78,405.03z"}})])]):_vm._e(),_c('media-play-button'),(_vm.initialPart)?_c('media-time-range',{class:{
            'media-control-bar-time-range__horizontal-initial': _vm.isHorizontal,
            'media-control-bar-time-range__vertical-initial': !_vm.isHorizontal,
          }}):_vm._e(),(!_vm.initialPart)?_c('media-time-range',{class:{
            'media-control-bar-time-range__horizontal-noninitial':
              _vm.isHorizontal,
            'media-control-bar-time-range__vertical-noninitial':
              !_vm.isHorizontal,
          }}):_vm._e(),(_vm.isHorizontal)?_c('media-playback-rate-button'):_vm._e()],1),_c('div',{staticClass:"disable-background",staticStyle:{"margin-right":"44px"}},[_c('media-mute-button',{staticStyle:{"position":"absolute","top":"2.4rem","right":"0rem"}}),(_vm.isChapterRequired)?_c('button',{staticClass:"media-controller__custom-button",staticStyle:{"position":"absolute","top":"4.8rem","right":"0rem"},attrs:{"id":"chapterBtn"},on:{"click":function($event){$event.preventDefault();return _vm.showChapter.apply(null, arguments)}}},[_c('svg',{staticStyle:{"enable-background":"new 0 0 430 430"},attrs:{"width":"21","height":"21","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","id":"Warstwa_3","x":"0px","y":"0px","viewBox":"0 0 430 430","xml:space":"preserve"}},[_c('path',{attrs:{"stroke":"#fff","stroke-width":"25","d":"M352.16,121.24H154.88c-3.31,0-6-2.69-6-6s2.69-6,6-6h197.28c3.31,0,6,2.69,6,6S355.47,121.24,352.16,121.24z"}}),_c('path',{attrs:{"stroke":"#fff","stroke-width":"25","d":"M275.56,320.76H78.28c-3.31,0-6-2.69-6-6s2.69-6,6-6h197.28c3.31,0,6,2.69,6,6S278.87,320.76,275.56,320.76z"}}),_c('path',{attrs:{"stroke":"#fff","stroke-width":"25","d":"M352.16,221.24H77.84c-3.31,0-6-2.69-6-6s2.69-6,6-6h274.32c3.31,0,6,2.69,6,6S355.47,221.24,352.16,221.24z"}})])]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }