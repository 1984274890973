import { Model } from "@vuex-orm/core"

export default class UserVideoEngagement extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'user_video_engagement'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.number(0),
            current_engaging_video_watching_seconds: this.number(0),
            current_engaging_video_duration: this.number(0)
        }
    }
}