<template>
  <div>
    <div
      v-if="choice.image_cta_url"
      style="margin-left: 3px; margin-right: 3px"
    >
      <div
        :class="{
          'syncc-widget__chocie-hover-fm3s534Ncle': this.isMouseOverChoice,
          'syncc-widget__choice-hover-leave-L1qcnFrj3':
            this.isMouseLeaveOverChoice,
        }"
        v-on:mouseover="mouseOverChoice"
        v-on:mouseleave="mouseLeaveChoice"
      >
        <div
          :class="{
            'syncc-widget__choice-image-cta-horizontal-j39Nkdcl':
              this.isHorizontalVideo,
            'syncc-widget__choice-image-cta-vertical-zn83nksdnk':
              !this.isHorizontalVideo,
          }"
          @click.prevent="handleConversion"
        >
          <img
            :src="choice.image_cta_url"
            style="object-fit: cover; border-radius: 4px"
            :class="{
              'syncc-widget__choice-image-object-horizontal-l2Kdnwikx':
                this.isHorizontalVideo,
              'syncc-widget__choice-image-object-vertical-Lp2mlxpd':
                !this.isHorizontalVideo,
            }"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="choice.in_choice_text_content && choice.choice_type"
      style="margin-left: 3px; margin-right: 3px"
    >
      <div
        v-if="choice.is_main_cta"
        :class="{
          'syncc-widget__chocie-hover-fm3s534Ncle': this.isMouseOverChoice,
          'syncc-widget__choice-hover-leave-L1qcnFrj3':
            this.isMouseLeaveOverChoice,
        }"
        v-on:mouseover="mouseOverChoice"
        v-on:mouseleave="mouseLeaveChoice"
      >
        <div
          class="syncc-widget__choice-main-cta-0k1Po3nc"
          :style="{ 'background-color': this.themeColor }"
          @click.prevent="handleConversion"
        >
          <span
            class="syncc-widget__choice-btn-content-bold-oO4jsnce"
            :style="{ color: this.mainCTABtnTextColor }"
          >
            {{ choice.in_choice_text_content }}
          </span>
        </div>
      </div>
      <div
        v-else
        :class="{
          'syncc-widget__chocie-hover-fm3s534Ncle': this.isMouseOverChoice,
          'syncc-widget__choice-hover-leave-L1qcnFrj3':
            this.isMouseLeaveOverChoice,
        }"
        v-on:mouseover="mouseOverChoice"
        v-on:mouseleave="mouseLeaveChoice"
      >
        <div
          class="syncc-widget__choice-plain-g241kNei"
          @click.prevent="handleConversion"
        >
          <play-original
            v-show="choice.choice_type == 'NEXT_PART'"
            :size="25"
            :color="playBtnColor"
          />
          <share-arrow v-show="choice.choice_type == 'EXTERNAL_LINK'" />
          <approved-message v-show="choice.choice_type == 'END'" />
          <span class="syncc-widget__choice-btn-content-plain-M12ln3sc">
            {{ choice.in_choice_text_content }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShareArrow from "./icons/svg/ShareArrow.vue";
import PlayOriginal from "./icons/svg/PlayOriginal.vue";
import ApprovedMessage from "./icons/svg/ApprovedMessage.vue";

export default {
  props: {
    choice: { type: Object, required: true },
    themeColor: { type: String, required: true },
    isHorizontalVideo: { type: Boolean, reqired: true },
  },
  components: {
    ShareArrow,
    PlayOriginal,
    ApprovedMessage,
  },
  data() {
    return {
      isMouseOverChoice: false,
      isMouseLeaveOverChoice: false,
      playBtnColor: "#fff",
      mainCTABtnTextColor: "",
    };
  },
  created() {
    if (this.choice.is_main_cta) {
      let rgbObj = this.hexToRGB(this.themeColor);
      this.mainCTABtnTextColor =
        rgbObj == null
          ? "#ffffff"
          : this.resolvedColorOf(rgbObj.r, rgbObj.g, rgbObj.b);
    }
  },
  methods: {
    resolvedColorOf(red, green, blue) {
      // see: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
      if (red * 0.299 + green * 0.587 + blue * 0.114 > 150) {
        return "#000000";
      } else {
        return "#ffffff";
      }
    },
    hexToRGB(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    mouseOverChoice() {
      this.isMouseOverChoice = true;
      this.isMouseLeaveOverChoice = false;
    },
    mouseLeaveChoice() {
      this.isMouseOverChoice = false;
      this.isMouseLeaveOverChoice = true;
      setTimeout(() => {
        this.isMouseLeaveOverChoice = false;
      }, 1000);
    },
    handleConversion() {
      this.$emit("on-process-update-watch-status");
      const chocieType = this.choice.choice_type;
      switch (chocieType) {
        case "NEXT_PART":
          this.$emit("on-process-choice-select", {
            eventType: this.choice.is_main_cta
              ? "CHOICE_SELECTED_MAIN_CTA_ENGAGED"
              : "CHOICE_SELECTED_VIDEO_PART_REQUEST",
            selectedChoiceLabel: this.choice.in_choice_text_content,
          });
          const nextPartId = this.choice.next_part;
          if (!nextPartId) {
            this.$router.push({ name: "thanks" });
            return;
          } else {
            this.$router.push({
              name: "story-videos",
              params: { id: nextPartId },
              query: { initial: false },
            });
            return;
          }
        case "EXTERNAL_LINK":
          const isQueryParamIncluded = this.hasQueryParamsOf(
            this.choice.link_url
          );
          const isEndsWithSlash = this.hasSlashEndsOf(this.choice.link_url);
          const linkWithSynccParam = isQueryParamIncluded
            ? this.choice.link_url + "&syncc-conversion=true"
            : isEndsWithSlash
            ? this.choice.link_url + "/?syncc-conversion=true"
            : this.choice.link_url + "?syncc-conversion=true";
          window.open(linkWithSynccParam, "_blank", "noopener");
          this.$emit("on-process-choice-select", {
            eventType: this.choice.is_main_cta
              ? "CHOICE_SELECTED_MAIN_CTA_ENGAGED"
              : "CHOICE_SELECTED_EXTERNAL_LINK_ROUTED",
            selectedChoiceLabel: this.choice.in_choice_text_content,
          });
          return;
        case "END":
          this.$router.push({ name: "thanks" });
          return;
      }
    },
    hasQueryParamsOf(url) {
      return url.includes("?");
    },
    hasSlashEndsOf(url) {
      return url.endsWith("/");
    },
  },
};
</script>

