
import { Model } from '@vuex-orm/core'

import PartMeta from "./PartMeta"

export default class Story extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'stories'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.attr(''),
            title: this.attr(''),
            is_live: this.boolean(false),
            thumbnail: this.attr(''),
            is_chapter_required: this.boolean(false),
            team_slug: this.attr(''),
            parts: this.hasMany(PartMeta, 'story_id')
        }
    }
}