import { Model } from "@vuex-orm/core"


import Part from "./Part"

export default class PartMeta extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'part_metas'

    static primaryKey = 'composition_id'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            composition_id: this.attr(''),
            story_id: this.attr(''),
            interaction_part: this.hasOne(Part, 'part_meta_id')
        }
    }
}