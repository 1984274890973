<template>
  <button
    v-if="removableCloseBtn"
    class="syncc-widget__flatbutton-universal-n1Bxlwrr"
    style="box-sizing: content-box; padding: 0"
    @click.prevent="emitClose"
  >
    <cross />
  </button>
</template>

<script>
import Cross from "./icons/svg/Cross.vue";

export default {
  components: {
    Cross,
  },
  data() {
    return {
      removableCloseBtn: true,
    };
  },
  created() {},
  methods: {
    emitClose() {
      this.$emit("close-el");
    },
  },
};
</script>


<style scoped>
.syncc-widget__flatbutton-universal-n1Bxlwrr {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: none;
  background-color: #2f3137;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}
</style>