import { Model } from '@vuex-orm/core'


export default class Customization extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'customizations'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.attr(''),
            story: this.attr(''),
            video_orientation: this.attr(''),
            icon_shape: this.attr(''),
            icon_position: this.attr(''),
            icon_border: this.attr(''),
            icon_size: this.attr(''),
            theme_color: this.attr(''),
            notification_badge: this.boolean(false),
            text_bubble: this.attr(''),
        }
    }
}