<template>
  <svg
    :width="size"
    :height="resolvedHeight"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="Warstwa_3"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 430 430"
    style="enable-background: new 0 0 430 430"
    xml:space="preserve"
  >
    <g>
      <path
        :stroke="color"
        stroke-width="25"
        d="M268.9,70.25c-4.9,0-9.8,1.3-14.2,3.8c-0.3,0.2-0.6,0.3-0.8,0.5l-100.1,69.2H73.3c-40.4,0-73.3,32.9-73.3,73.3v55.6
                c0,40.4,32.9,73.3,73.3,73.3h84.5l95.9,69.2c0.3,0.2,0.7,0.5,1,0.7c4.3,2.5,9.2,3.8,14.2,3.8l0,0c7.4,0,14.4-2.8,19.7-7.9
                c5.6-5.4,8.7-12.6,8.7-20.4V98.65C297.3,82.95,284.6,70.25,268.9,70.25z M272.8,391.25c0,1.1-0.4,2-1.1,2.8
                c-0.7,0.7-1.8,1.1-2.7,1.1c-0.5,0-1-0.1-1.5-0.3l-98.4-71.1c-2.1-1.5-4.6-2.3-7.2-2.3H73.3c-26.9,0-48.8-21.9-48.8-48.8v-55.6
                c0-26.9,21.9-48.8,48.8-48.8h84.3c2.5,0,4.9-0.8,7-2.2l102.7-71c0.5-0.3,1.1-0.4,1.6-0.4c1.6,0,3.9,1.2,3.9,3.9V391.25z"
      />
      <path
        :stroke="color"
        stroke-width="25"
        d="M486.1,171.35c-4.8-4.8-12.5-4.8-17.3,0l-56.3,56.3l-56.3-56.3c-4.8-4.8-12.5-4.8-17.3,0c-4.8,4.8-4.8,12.5,0,17.3
                l56.3,56.3l-56.3,56.3c-4.8,4.8-4.8,12.5,0,17.3c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l56.3-56.3l56.3,56.3
                c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-56.5-56.3l56.3-56.3C490.8,183.85,490.8,176.15,486.1,171.35z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    size: { type: Number, required: true },
  },
  computed: {
    resolvedHeight() {
      return parseInt(this.size * 0.66);
    },
  },
};
</script>
