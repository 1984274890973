import { Model } from "@vuex-orm/core"

export default class UserDetail extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'user_detail'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.number(0),
            platform: this.attr(''), // 'mobile' or 'desktop'
            volume_state: this.attr(''), // 'mute' or 'unmute'
        }
    }
}