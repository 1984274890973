var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"syncc-widget__zoom-content--storyicon-4aqmLwncEr",on:{"mouseover":_vm.mouseOverWidgetIcon,"mouseleave":_vm.mouseLeaveWidgetIcon}},[_c('div',{ref:"videoIconBtn",staticStyle:{"display":"none"}}),_c('div',{ref:"loadingImgPreLoad",staticStyle:{"display":"none"}},[_c('img',{attrs:{"src":_vm.loader,"width":"1","height":"1"}})]),_c('transition',{attrs:{"name":"bottom"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.iconShow),expression:"iconShow"}],class:{
        'syncc-widget__icon-on-scroling-37MMepod': this.userOnTopScreen,
        'syncc-widget__icon-on-scroling-j4k2xDEg': !this.userOnTopScreen,
        'syncc-widget__icon_hover-Me92Ndxs2': this.isMouseOverWidgetIcon,
        'syncc-widget__icon_hover-leave-k31lAdn5o':
          this.isMouseLeaveOverWidgetIcon,
      },staticStyle:{"animation-duration":"0.6s !important","animation-timing-function":"ease-out !important","animation-delay":"0s !important","animation-iteration-count":"1 !important","animation-direction":"normal !important","animation-fill-mode":"none !important","animation-play-state":"running !important"},style:(_vm.styles.iconContainerStyle)},[(_vm.iconContentTag == ('img' || 'gif'))?_c('img',{style:(_vm.styles.iconStyle),attrs:{"id":"video-icon","src":_vm.iconSrc},on:{"load":_vm.imageLoaded,"click":function($event){$event.preventDefault();return _vm.conversionVideo.apply(null, arguments)}}}):_vm._e(),_c('span',{class:_vm.notificationBadgeClass}),(_vm.iconShape == 'RECTANGLE')?_c('mute',{staticClass:"syncc-widget__welcome-icon-mute-93nxKnwl",attrs:{"size":26,"color":_vm.playIconColor},on:{"click":function($event){$event.preventDefault();return _vm.conversionVideo.apply(null, arguments)}}}):_vm._e(),(_vm.iconShape == 'RECTANGLE')?_c('play-original',{staticClass:"syncc-widget__welcome-icon-play-video-btn-nl39Nfj2",attrs:{"size":30,"color":_vm.playIconColor},on:{"click":function($event){$event.preventDefault();return _vm.conversionVideo.apply(null, arguments)}}}):_vm._e(),(
          _vm.closeBtnShow &&
          _vm.notificationBadgeClass ==
            'syncc-widget__notification-badge-none-6L2doxp'
        )?_c('close-btn',{staticClass:"syncc-widget__welcome-icon-close-btn-LdeN298b",on:{"close-el":_vm.closeIcon}}):_vm._e()],1)]),_c('transition',{attrs:{"name":"bottom"}},[_c('text-bubble',{directives:[{name:"show",rawName:"v-show",value:(_vm.iconShow && _vm.userOnTopScreen),expression:"iconShow && userOnTopScreen"}],staticStyle:{"animation-duration":"0.6s !important","animation-timing-function":"ease-out !important","animation-delay":"0s !important","animation-iteration-count":"1 !important","animation-direction":"normal !important","animation-fill-mode":"none !important","animation-play-state":"running !important"},attrs:{"textBubbleContent":_vm.textBubbleContent,"textBubbleStyle":_vm.styles.textBubbleStyle},on:{"conversion-video":_vm.conversionVideo}})],1),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"synccPreloadVideoContainer"}},[(_vm.iconShow)?_c('preload-first-video',{attrs:{"playbackId":_vm.preloadVideo.playbackId,"jwt":_vm.preloadVideo.jwt}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }