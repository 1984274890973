<template>
  <p
    v-if="textBubbleContent"
    class="syncc-widget__text-bubble-8N3klwN"
    :style="textBubbleStyle"
    v-on:click.prevent="conversionVideo"
  >
    {{ textBubbleContent }}
  </p>
</template>

<script>
export default {
  props: {
    textBubbleContent: { type: String, required: false },
    textBubbleStyle: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    conversionVideo() {
      this.$emit("conversion-video");
    },
  },
};
</script>


<style scoped>
.syncc-widget__text-bubble-8N3klwN {
  position: fixed;
  max-width: 600px;
  background-color: #fff;
  padding: 1.125em 1.5em;
  font-size: 1.25em;
  font-weight: 600;
  z-index: 999 !important;
  color: rgba(37, 37, 37, 0.9);
  letter-spacing: 1px;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.syncc-widget__text-bubble-8N3klwN::before {
  position: fixed;
  width: 600px;
  height: 0;
  bottom: 100%;
  left: 1.5em;
  z-index: 999 !important;
  border: 0.75rem solid transparent;
  border-top: none;
  border-bottom-color: #fff;
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
}
</style>