<template>
  <div>
    <h1>Loading</h1>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  created() {
  },
};
</script>
