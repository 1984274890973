import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'

import getters from './getters'
import mutations from './mutations'
import actions from "./actions"

import Choice from "../models/Choice"
import Config from "../models/Config"
import Customization from "../models/Customization"
import EngagedUser from "../models/EngagedUser"
import Meta from "../models/Meta"
import Part from "../models/Part"
import PartMeta from "../models/PartMeta"
import Story from "../models/Story"
import UserDetail from "../models/UserDetail"
import UserEngagement from "../models/UserEngagement"
import UserVideoEngagement from "../models/UserVideoEngagement"
import Video from "../models/Video"

Vue.use(Vuex)

// Vuex ORM Registrasion
// See detail: https://vuex-orm.org/guide/prologue/getting-started.html#define-models
// Create a new instance of Database.
const database = new VuexORM.Database()

// Register Models to Database.
database.register(Choice)
database.register(Config)
database.register(Customization)
database.register(EngagedUser)
database.register(Meta)
database.register(Part)
database.register(PartMeta)
database.register(Story)
database.register(UserEngagement)
database.register(UserVideoEngagement)
database.register(UserDetail)
database.register(Video)


export default new Vuex.Store({
    state: {},
    getters,
    mutations,
    actions,
    plugins: [VuexORM.install(database)]
})