import Cookies from "js-cookie";
import axios from "axios"

const publicPath = process.env.NODE_ENV === 'production' ?
    'https://app.syncc.one/' : 'http://localhost:8000/';

const isPreviewEnv = location.protocol === "https:" && (location.hostname === "app.syncc.one" || location.hostname === "syncc-widget-dev.web.app")

export default {
    fetchStoryDetails(ctx, { widgetId }) {
        return new Promise((resolve, reject) => {
            axios.get(publicPath + `stories/api/story/${widgetId}/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": Cookies.get("csrftoken"),
                },
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
    checkIfExistingEngagedUserOrNot(ctx, { teamSlug, engagedUserId }) {
        if (isPreviewEnv) {
            return new Promise((resolve, _) => {
                return resolve()
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.get(publicPath + `stories/api/${teamSlug}/engaged-user/${engagedUserId}/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": Cookies.get("csrftoken"),
                    },
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    },
    createEngagedUser(ctx, { teamSlug, personId }) {
        if (isPreviewEnv) {
            return new Promise((resolve, _) => {
                return resolve()
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.post(publicPath + `stories/api/${teamSlug}/engaged-user/new/?person=${personId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": Cookies.get("csrftoken"),
                    },
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    },
    createAnonymousEngagedUser(ctx, { teamSlug }) {
        if (isPreviewEnv) {
            return new Promise((resolve, _) => {
                return resolve()
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.post(publicPath + `stories/api/${teamSlug}/engaged-user/new/`, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": Cookies.get("csrftoken"),
                    },
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    },
    createEngagement(ctx, {
        storyId,
        impression,
        play,
        totalPlayCnt,
        interaction,
        totalInteractionCnt,
        btnClick,
        complete,
        externalLinkRouting,
        mainCTAEngaged,
        timeSpentInSeconds,
        os,
        location,
        browser,
        shareType,
        isUnique
    }) {
        if (isPreviewEnv) {
            return new Promise((resolve, _) => {
                return resolve()
            })
        } else {
            return new Promise((resolve, reject) => {
                const params = {
                    story: storyId,
                    impression: impression,
                    play: play,
                    total_play_cnt: totalPlayCnt,
                    interaction: interaction,
                    total_interaction_cnt: totalInteractionCnt,
                    btn_click: btnClick,
                    complete: complete,
                    external_link_routing: externalLinkRouting,
                    main_cta_engaged: mainCTAEngaged,
                    time_spent_in_seconds: timeSpentInSeconds,
                    os: os,
                    location: location,
                    browser: browser,
                    share_type: shareType,
                    is_unique: isUnique,
                }
                axios.post(publicPath + `stories/api/engagement/`, params, {
                    headers: {
                        "Content-type": "application/json",
                        "X-CSRFToken": Cookies.get("csrftoken"),
                    },
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    },
    updateEngagementWithEngagedUser(ctx, {
        engagementId,
        engagedUserId
    }) {
        if (isPreviewEnv) {
            return new Promise((resolve, _) => {
                return resolve()
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.post(publicPath + `stories/api/engagement/${engagementId}/set-engaged-user/${engagedUserId}/`, {
                    headers: {
                        "Content-type": "application/json",
                        "X-CSRFToken": Cookies.get("csrftoken"),
                    },
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    },
    createPartEngagementEvent(ctx, {
        partId,
        engagementId,
        eventType,
        selctedChoiceLabel,
        choiceLabels,
        btnClick,
        timeSpentInSeconds,
        videoPercentageWatched
    }) {
        if (isPreviewEnv) {
            return new Promise((resolve, _) => {
                return resolve()
            })
        } else {
            return new Promise((resolve, reject) => {
                const params = {
                    interaction_part: partId,
                    story_engagement: engagementId,
                    event_type: eventType,
                    selected_choice_label: selctedChoiceLabel,
                    choice_labels: choiceLabels,
                    btn_click: btnClick,
                    time_spent_in_seconds: timeSpentInSeconds,
                    video_percentage_watched: videoPercentageWatched,
                }
                axios.post(publicPath + `stories/api/part-engagement/`, params, {
                    headers: {
                        "Content-type": "application/json",
                        "X-CSRFToken": Cookies.get("csrftoken"),
                    },
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    },
    setVideoWatchStatsOnPartEngagementEvent(ctx, {
        partEngagementId,
        timeSpentInSeconds,
        videoPercentageWatched
    }) {
        if (isPreviewEnv) {
            return new Promise((resolve, _) => {
                return resolve()
            })
        } else {
            return new Promise((resolve, reject) => {
                const params = {
                    time_spent_in_seconds: timeSpentInSeconds,
                    video_percentage_watched: videoPercentageWatched,
                }
                axios.patch(publicPath + `stories/api/part-engagement/${partEngagementId}/`, params, {
                    headers: {
                        "Content-type": "application/json",
                        "X-CSRFToken": Cookies.get("csrftoken"),
                    },
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    },
}