import { Model } from '@vuex-orm/core'

export default class Video extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'videos'

    static primaryKey = 'video_asset_id'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            video_asset_id: this.attr(''),
            part_id: this.attr(''),
            mux_jwt: this.attr(''),
            mux_jwt_for_gif: this.attr(''),
            mux_jwt_for_img: this.attr(''),
            mux_asset_id: this.attr(''),
            mux_playback_id: this.attr(''),
            is_active: this.boolean(false),
        }
    }
}