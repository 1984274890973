import { render, staticRenderFns } from "./Chapter.vue?vue&type=template&id=3ce6585c&scoped=true&"
import script from "./Chapter.vue?vue&type=script&lang=js&"
export * from "./Chapter.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3ce6585c",
  null
  ,true
)

export default component.exports