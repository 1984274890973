<template>
  <svg
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Warstwa_3"
    x="0px"
    y="0px"
    viewBox="0 0 430 430"
    style="enable-background: new 0 0 430 430"
    xml:space="preserve"
  >
    <g>
      <path
        :stroke="color"
        stroke-width="25"
        d="M98.09,376.52c-1,0-2-0.24-2.92-0.73c-2-1.07-3.25-3.16-3.25-5.44V59.65c0-2.27,1.25-4.36,3.25-5.44
		c2-1.07,4.43-0.96,6.33,0.3l233.82,155.35c1.72,1.14,2.75,3.07,2.75,5.14s-1.03,3.99-2.75,5.14L101.5,375.49
		C100.47,376.18,99.28,376.52,98.09,376.52z M104.26,71.15v287.7L320.76,215L104.26,71.15z"
      />
    </g>
  </svg>
</template>


<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    size: { type: Number, required: true },
  },
};
</script>
