<template>
  <router-view v-if="loaded" :key="$route.fullPath" />
</template>

<script>
import Story from "../models/Story";
import Meta from "../models/Meta";
import Customization from "../models/Customization";
import Config from "../models/Config";
import UserDetail from "../models/UserDetail";
import EngagedUser from "../models/EngagedUser";
import UserVideoEngagement from "../models/UserVideoEngagement";
import UserEngagement from "../models/UserEngagement";

import store from "../store/index";

export default {
  components: {},
  props: {
    widgetId: { type: String, required: true },
    config: { type: Object, required: true },
  },
  data() {
    return {
      loaded: false,
      isUsable: false,
    };
  },
  async created() {
    const payload = {
      widgetId: this.widgetId,
    };
    const triggerUrlsArr = this.config.trigger_urls;
    const triggerRulesDict = this.config.trigger_rules;
    if (this.isTriggerableUrl(triggerUrlsArr, triggerRulesDict)) {
      await store.dispatch("fetchStoryDetails", payload).then((response) => {
        if (response.data.story.is_live || this.isPreviewEnv()) {
          const { os, browser } = this.getEnv();
          UserEngagement.insert({
            data: {
              id: 0,
              share_type: "WIDGET",
              os: os,
              browser: browser,
            },
          });
          Story.insert({ data: response.data.story });
          Meta.insert({ data: response.data.meta });
          Customization.insert({ data: response.data.customization });
          Config.insert({ data: this.config });
          EngagedUser.insert({ data: { id: 0 } });
          UserDetail.insert({
            data: {
              id: 0,
              platform: this.detectMobile() ? "mobile" : "desktop",
              volume_state: "mute",
            },
          });
          UserVideoEngagement.insert({ data: { id: 0 } });
          this.$router.push({ name: "story-icon" });
          this.loaded = true;
          this.isUsable = true;
        }
      });
    }
  },
  async mounted() {},
  beforeDestroy() {},
  watch: {
    isUsable: {
      handler: function (val, _) {
        if (this.isUsable) {
          this.sendEngagementCreateRequest();
        }
      },
    },
  },
  computed: {},
  methods: {
    isTriggerableUrl(triggerUrlsArr, triggerRulesDict) {
      const currentLocation = this.resolvedCurrentLocation();

      if (!triggerRulesDict) {
        // If detailed rules(triggerRulesDict) does not exist
        if (triggerUrlsArr.includes("*")) return true;

        for (var i = 0; i < triggerUrlsArr.length; i++) {
          const targetUrl = triggerUrlsArr[i];
          const isTriggerable = currentLocation.startsWith(targetUrl);
          if (isTriggerable) return true;
        }

        return triggerUrlsArr.includes(currentLocation);
      } else {
        // If detailed rules(triggerRulesDict) exist
        const equalToUrls = triggerRulesDict.equals_to;
        for (var i = 0; i < equalToUrls.length; i++) {
          const targetUrl = equalToUrls[i];
          const isTriggerable = currentLocation === targetUrl;
          if (isTriggerable) return true;
        }
      }
    },
    resolvedCurrentLocation() {
      let currentLocationFullPath = location.href;
      let currentLocationWithoutQueryParams = this.getUrlWithoutQuery(
        currentLocationFullPath
      );
      const lastPathIndex = currentLocationWithoutQueryParams.length - 1;
      const currentLocation =
        currentLocationWithoutQueryParams[lastPathIndex] == "/"
          ? currentLocationWithoutQueryParams.substr(0, lastPathIndex)
          : currentLocationWithoutQueryParams;
      return currentLocation;
    },
    getUrlWithoutQuery(url) {
      var u = new URL(url);
      u.hash = "";
      u.search = "";
      return u.toString();
    },
    isPreviewEnv() {
      return (
        location.protocol === "https:" && location.hostname === "app.syncc.one"
      );
    },
    detectMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getEnv() {
      var ua = window.navigator.userAgent.toLowerCase();

      var os = "";
      if (ua.indexOf("windows nt") !== -1) {
        os = "Microsoft Windows";
      } else if (ua.indexOf("android") !== -1) {
        os = "Android";
      } else if (ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1) {
        os = "iOS";
      } else if (ua.indexOf("mac os x") !== -1) {
        os = "macOS";
      } else {
        os = "不明";
      }

      var browser = "";
      if (
        ua.indexOf("edge") !== -1 ||
        ua.indexOf("edga") !== -1 ||
        ua.indexOf("edgios") !== -1
      ) {
        browser = "Microsoft Edge";
      } else if (ua.indexOf("opera") !== -1 || ua.indexOf("opr") !== -1) {
        browser = "Opera";
      } else if (ua.indexOf("samsungbrowser") !== -1) {
        browser = "Samsung Internet Browser";
      } else if (ua.indexOf("ucbrowser") !== -1) {
        browser = "UC Browser";
      } else if (ua.indexOf("chrome") !== -1 || ua.indexOf("crios") !== -1) {
        browser = "Google Chrome";
      } else if (ua.indexOf("firefox") !== -1 || ua.indexOf("fxios") !== -1) {
        browser = "Mozilla Firefox";
      } else if (ua.indexOf("safari") !== -1) {
        browser = "Safari";
      } else if (ua.indexOf("msie") !== -1 || ua.indexOf("trident") !== -1) {
        browser = "Internet Explorer";
      } else {
        browser = "不明";
      }
      return { os: os, browser: browser };
    },
    async sendEngagementCreateRequest() {
      let story = Story.all()[0];
      let userEngagement = UserEngagement.all()[0];
      const { os, browser } = this.getEnv();

      const payload = {
        storyId: story.id,
        impression: true,
        play: userEngagement.play,
        totalPlayCnt: 0,
        interaction: userEngagement.interaction,
        totalInteractionCnt: 0,
        btnClick: userEngagement.btn_click,
        complete: userEngagement.complete,
        externalLinkRouting: userEngagement.external_link_routing,
        mainCTAEngaged: userEngagement.main_cta_engaged,
        timeSpentInSeconds: 0,
        os: os,
        location: userEngagement.location,
        browser: browser,
        shareType: "WIDGET",
        isUnique: true,
      };

      await store.dispatch("createEngagement", payload).then((response) => {
        if (response) {
          let engagementId = response.data.engagement_id;
          UserEngagement.update({
            where: "0",
            data: {
              engagement_id: engagementId,
            },
          });
        }
      });
    },
  },
};
</script>