<template>
  <div
    v-show="videoShow"
    class="syncc-widget__zoom-content--storyvideo-7bct2Odbwe"
    :class="{
      'syncc-widget__zoom-content-horizontal--storyvideo-SDe3pxn1':
        isHorizontalVideo,
    }"
  >
    <div key="loadingScreen">
      <transition
        v-if="initialPart"
        name="custom-bottom"
        enter-active-class="bottom-enter bottom-enter-active"
        leave-active-class="fade-leave-to fade-leave-active"
        @after-enter="onAfterEnterLoadingImg"
      >
        <interactive-video-loading
          v-show="isLoadingVideo"
          :containerPosition="videoLoadingContainerPosition"
          :videoOrientationStyle="styles.videoOrientationStyle"
          :logo="logo"
          v-on:route-to-syncc-service-page="routeToSynccServicePage"
          style="
            animation-duration: 0.3s !important;
            animation-timing-function: ease-out !important;
            animation-delay: 0s !important;
            animation-iteration-count: 1 !important;
            animation-direction: normal !important;
            animation-fill-mode: none !important;
            animation-play-state: running !important;
          "
        />
      </transition>
      <interactive-video-loading
        v-show="isLoadingVideo && !initialPart"
        :containerPosition="videoLoadingContainerPosition"
        :videoOrientationStyle="styles.videoOrientationStyle"
        :logo="logo"
        v-on:route-to-syncc-service-page="routeToSynccServicePage"
      />
    </div>
    <div
      key="videoScreen"
      v-show="afterEnterTransition || !initialPart"
      :style="styles.videoContainerStyle"
    >
      <interactive-video
        v-if="!this.isSafari"
        id="interactive-video-area"
        class="syncc-widget__video-K8eklU93N"
        :style="styles.videoOrientationStyle"
        ref="interactiveVideo"
        :playbackId="part.video.mux_playback_id"
        :jwt="part.video.mux_jwt"
        :initialPart="initialPart"
        :isHorizontal="isHorizontalVideo"
        :videoOrientationStyle="styles.videoOrientationStyle"
        :isChapterRequired="isChapterRequired"
        :initialVolumeState="initialVolumeState"
        v-on:can-play="finishLoadingAndDisplayVideo"
        v-on:on-show-chapter-mode="showChapter"
        v-on:on-get-video-duration="setVideoDuration"
        v-on:on-update-video-process-time="handleTimeUpdateProc"
        v-on:on-process-update-watch-status="sendWatchStatusEngagementEvent"
        v-on:set-mute="setMute"
        v-on:set-unmute="setUnmute"
      >
      </interactive-video>
      <interactive-video-for-safari
        v-else-if="this.isSafari"
        id="interactive-video-area"
        class="syncc-widget__video-K8eklU93N"
        :style="styles.videoOrientationStyle"
        ref="interactiveVideo"
        :playbackId="part.video.mux_playback_id"
        :jwt="part.video.mux_jwt"
        :initialPart="initialPart"
        :isHorizontal="isHorizontalVideo"
        :videoOrientationStyle="styles.videoOrientationStyle"
        :isChapterRequired="isChapterRequired"
        :initialVolumeState="initialVolumeState"
        v-on:can-play="finishLoadingAndDisplayVideo"
        v-on:on-show-chapter-mode="showChapter"
        v-on:on-get-video-duration="setVideoDuration"
        v-on:on-update-video-process-time="handleTimeUpdateProc"
        v-on:on-process-update-watch-status="sendWatchStatusEngagementEvent"
        v-on:set-mute="setMute"
        v-on:set-unmute="setUnmute"
      >
      </interactive-video-for-safari>
      <transition name="fade">
        <div
          v-if="showChapterMode"
          :class="{
            'syncc-widget__chapter-container-area-horizontal-n5Nekw2snc':
              this.isHorizontal,
            'syncc-widget__chapter-container-area-vertical-8oLXem9dma':
              !this.isHorizontal,
          }"
        >
          <chapter
            :nowPlayingPart="part.part_id"
            v-on:close-chapter="closeChapter"
            v-on:on-process-update-watch-status="sendWatchStatusEngagementEvent"
          />
        </div>
      </transition>
      <div class="syncc-widget__on-video-overlay-container-lN3AAd82">
        <transition name="fade">
          <div
            class="syncc-widget__video-message-text-9dlPcmss"
            v-show="messageShow"
          >
            <span :style="{ color: part.message_text_color_hex }">{{
              part.message_text_content
            }}</span>
          </div>
        </transition>
        <transition name="fade">
          <div
            id="choices-container"
            v-show="choiceShow"
            class="syncc-widget__choices-container-on-video-Nre77iuc"
            :class="{
              'syncc-widget__choices-container-on-video-horizontal-dm21NOwcc':
                this.isHorizontalVideo,
              'syncc-widget__choices-container-on-video-vertical-dm21NOwcc':
                !this.isHorizontalVideo,
            }"
          >
            <single-choice
              v-for="choice in choices"
              :key="choice.id"
              :choice="choice"
              :themeColor="themeColor"
              :isHorizontalVideo="isHorizontalVideo"
              v-on:next-video-part="disposeVideo"
              v-on:on-process-update-watch-status="
                sendWatchStatusEngagementEvent
              "
              v-on:on-process-choice-select="sendChoiceSelectEngagementEvent"
            ></single-choice>
            <single-choice
              v-for="choice in mainCTAChoices"
              :key="choice.id"
              :choice="choice"
              :themeColor="themeColor"
              :isHorizontalVideo="isHorizontalVideo"
              v-on:next-video-part="disposeVideo"
              v-on:on-process-update-watch-status="
                sendWatchStatusEngagementEvent
              "
              v-on:on-process-choice-select="sendChoiceSelectEngagementEvent"
            ></single-choice>
          </div>
        </transition>
      </div>
      <div
        style="
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000 !important;
        "
        @click="routeToSynccServicePage"
      >
        <img :src="logo" style="max-width: 95px" />
      </div>
      <close-btn
        v-show="!showChapterMode"
        class="syncc-widget__video-close-btn-Ake8ck3"
        v-on:close-el="closeVideoContainer"
      />
    </div>
  </div>
</template>

<script>
import Config from "../models/Config";
import Customization from "../models/Customization";
import Choice from "../models/Choice";
import Part from "../models/Part";
import Story from "../models/Story";
import EngagedUser from "../models/EngagedUser";
import UserDetail from "../models/UserDetail";
import UserEngagement from "../models/UserEngagement";
import UserVideoEngagement from "../models/UserVideoEngagement";

import Chapter from "../components/UI/Chapter.vue";
import InteractiveVideo from "../components/UI/InteractiveVideo.vue";
import InteractiveVideoForSafari from "../components/UI/InteractiveVideoForSafari.vue";
import InteractiveVideoLoading from "../components/UI/InteractiveVideoLoading.vue";
import SingleChoice from "../components/UI/SingleChoice.vue";
import CloseBtn from "../components/UI/CloseBtn.vue";

import store from "../store/index";

export default {
  props: {},
  components: {
    Chapter,
    InteractiveVideo,
    InteractiveVideoForSafari,
    InteractiveVideoLoading,
    SingleChoice,
    CloseBtn,
  },
  data() {
    return {
      part: Object,
      choices: Array,
      mainCTAChoices: Array,
      showAnswerChoiceTimeIntervalInSec: Number,
      heartbeatEngagementRequestSecCount: 0,
      initialPart: false,
      isLoadingVideo: false,
      afterEnterTransition: false,
      videoShow: true,
      closeBtnShow: true,
      choiceShow: false,
      messageShow: false,
      isChapterRequired: false,
      showChapterMode: false,
      videoLoadingContainerPosition: "",
      isHorizontalVideo: true,
      initialVolumeState: "mute",
      styles: {
        videoContainerStyle: {},
        videoOrientationStyle: {},
        videoShowUpStyle: {},
      },
      logo: "",
      themeColor: "",
      doneWatchProgressEngagementEventRequest: false,
      doneCreateVideoViewEngagementEventRequest: false,
      isSafari: false,
    };
  },
  created() {
    // Detect Browser is Safari or not
    var ua = window.navigator.userAgent.toLowerCase();
    if (
      ua.indexOf("edge") !== -1 ||
      ua.indexOf("edga") !== -1 ||
      ua.indexOf("edgios") !== -1
    ) {
    } else if (ua.indexOf("opera") !== -1 || ua.indexOf("opr") !== -1) {
    } else if (ua.indexOf("samsungbrowser") !== -1) {
    } else if (ua.indexOf("ucbrowser") !== -1) {
    } else if (ua.indexOf("chrome") !== -1 || ua.indexOf("crios") !== -1) {
    } else if (ua.indexOf("firefox") !== -1 || ua.indexOf("fxios") !== -1) {
    } else if (ua.indexOf("safari") !== -1) {
      this.isSafari = true;
    } else if (ua.indexOf("msie") !== -1 || ua.indexOf("trident") !== -1) {
    } else {
    }

    /* Video handling */
    const partId = this.$route.params["id"];
    this.initialPart = this.$route.query["initial"];
    this.part = Part.query().with("video").where("part_id", partId).get()[0];
    this.choices = Choice.query()
      .where("part_id", partId)
      .where("is_main_cta", false)
      .orderBy("display_priority", "asc")
      .get();
    this.mainCTAChoices = Choice.query()
      .where("part_id", partId)
      .where("is_main_cta", true)
      .orderBy("display_priority", "asc")
      .get();

    if (this.initialPart) {
      this.styles.videoShowUpStyle = `
          animation-duration: 0.6s !important;
          animation-timing-function: ease-out !important;
          animation-delay: 0s !important;
          animation-iteration-count: 1 !important;
          animation-direction: normal !important;
          animation-fill-mode: none !important;
          animation-play-state: running !important;
      `;
    }

    /* Layout handling */
    let customization = Customization.all()[0];
    this.themeColor = customization.theme_color;

    let config = Config.all()[0];
    this.showAnswerChoiceTimeIntervalInSec = this.part.choices_display_timeout;

    const userDetail = UserDetail.all()[0];
    this.initialVolumeState = userDetail.volume_state;

    this.isHorizontalVideo =
      customization.video_orientation == "HORIZONTAL_VIDEO";
    this.styles.videoOrientationStyle =
      customization.video_orientation == "HORIZONTAL_VIDEO"
        ? { width: "640px !important", height: "360px" }
        : userDetail.platform == "mobile"
        ? {
            width: "100vw !important",
            height: "86vh !important",
            borderRadius: "13.6px 13.6px 0 0 !important",
          }
        : { width: "360px !important", height: "640px" };

    let additionalMarginBottom = 0;
    if (Object.keys(config.additional_margin_bottoms).length > 1) {
      if (userDetail.platform == "mobile") {
        additionalMarginBottom = config.additional_margin_bottoms.mobile;
      } else {
        additionalMarginBottom = config.additional_margin_bottoms.desktop;
      }
    } else {
      additionalMarginBottom = config.additional_margin_bottom;
    }

    let iconPositionMarginBottomInPixel = 20 + additionalMarginBottom;

    if (userDetail.platform == "mobile" && !this.isHorizontalVideo) {
      var videoPositionStyle =
        customization.icon_position == "LEFT"
          ? {
              bottom: "0px",
              left: "0px",
            }
          : {
              bottom: "0px",
              right: "0px",
            };
    } else {
      var videoPositionStyle =
        customization.icon_position == "LEFT"
          ? {
              bottom: String(iconPositionMarginBottomInPixel) + "px",
              left: "20px",
            }
          : {
              bottom: String(iconPositionMarginBottomInPixel) + "px",
              right: "20px",
            };
    }

    this.videoLoadingContainerPosition = videoPositionStyle;
    this.styles.videoContainerStyle = {
      ...videoPositionStyle,
      borderRadius: "2rem",
      display: "block",
      position: "fixed",
      "-webkit-user-select": "none",
      margin: "auto",
      "z-index": "998 !important",
    };

    /* Chapter handling */
    let story = Story.all()[0];
    this.isChapterRequired = story.is_chapter_required;

    /* Syncc brand logo handling */
    const isProduction = process.env.NODE_ENV === "production";
    if (isProduction) {
      this.logo = "https://syncc-widget.web.app/img/syncc-logo.png";
    } else {
      this.logo = require("@/assets/img/syncc-logo.png");
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoadingVideo = true;
    }, 1);
    window.addEventListener(
      "beforeunload",
      this.handleWatchProgressPatchEventRequest
    );
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    async setMute() {
      await UserDetail.update({
        id: 0,
        volume_state: "mute",
      });
    },
    async setUnmute() {
      await UserDetail.update({
        id: 0,
        volume_state: "unmute",
      });
    },
    onAfterEnterLoadingImg() {
      this.afterEnterTransition = true;
    },
    finishLoadingAndDisplayVideo() {
      this.isLoadingVideo = false;
      if (!this.doneCreateVideoViewEngagementEventRequest) {
        this.sendVideoViewStartPartEngagementEvent();
      }
    },
    setVideoDuration(duration) {
      UserVideoEngagement.update({
        data: {
          id: 0,
          current_engaging_video_duration: parseInt(duration),
        },
      });
    },
    handleTimeUpdateProc(currentTime) {
      // Choice Timeset
      if (
        currentTime > this.showAnswerChoiceTimeIntervalInSec &&
        !this.choiceShow
      ) {
        this.choiceShow = true;
        this.messageShow = true;
      }
      // Hanlde engagement
      if (currentTime) {
        let videoEngagement = UserVideoEngagement.all()[0];
        let videoWatchingSeconds =
          videoEngagement.current_engaging_video_watching_seconds;
        if (currentTime > videoWatchingSeconds) {
          UserVideoEngagement.update({
            data: {
              id: 0,
              current_engaging_video_watching_seconds: parseInt(currentTime),
            },
          });
          if (
            parseInt(currentTime) % 3 === 0 &&
            parseInt(currentTime) > this.heartbeatEngagementRequestSecCount
          ) {
            this.heartbeatEngagementRequestSecCount = parseInt(currentTime);
            // Heartbeat like api request per every 3 seconds
            this.sendWatchStatusEngagementEvent(true);
          }
        }
      }
    },
    handleWatchProgressPatchEventRequest() {
      if (!this.doneWatchProgressEngagementEventRequest) {
        this.sendWatchStatusEngagementEvent(false);
      }
    },
    sendVideoViewStartPartEngagementEvent() {
      if (this.doneCreateVideoViewEngagementEventRequest) {
        return;
      } else {
        this.doneCreateVideoViewEngagementEventRequest = true;
        let engagement = UserEngagement.all()[0];
        const payload = {
          partId: this.part.part_id,
          engagementId: engagement.engagement_id,
          eventType: "VIDEO_VIEW_STARTED",
          selctedChoiceLabel: "",
          choiceLabels: [],
          btnClick: 1,
          timeSpentInSeconds: 0,
          videoPercentageWatched: 0,
        };
        store
          .dispatch("createPartEngagementEvent", payload)
          .then((response) => {
            if (response) {
              EngagedUser.update({
                where: "0",
                data: {
                  current_engaging_part_event_id:
                    response.data.part_engagement_id,
                },
              });
            }
          });
      }
    },
    sendWatchStatusEngagementEvent(isHeartbeatRequest = false) {
      if (!isHeartbeatRequest) {
        this.doneWatchProgressEngagementEventRequest = true;
      }
      let engagedUser = EngagedUser.all()[0];
      let videoEngagement = UserVideoEngagement.all()[0];
      var videoPercentageWatched =
        (videoEngagement.current_engaging_video_watching_seconds /
          videoEngagement.current_engaging_video_duration) *
        100;
      if (engagedUser.current_engaging_part_event_id) {
        const payload = {
          partEngagementId: engagedUser.current_engaging_part_event_id,
          timeSpentInSeconds:
            videoEngagement.current_engaging_video_watching_seconds,
          videoPercentageWatched: parseInt(videoPercentageWatched),
        };
        store
          .dispatch("setVideoWatchStatsOnPartEngagementEvent", payload)
          .then((_) => {
            if (!isHeartbeatRequest) {
              UserVideoEngagement.update({
                data: {
                  id: 0,
                  current_engaging_video_watching_seconds: 0,
                },
              });
            }
          });
      }
    },
    sendChoiceSelectEngagementEvent({ eventType, selectedChoiceLabel }) {
      let engagement = UserEngagement.all()[0];
      let allChoices = this.choices.concat(this.mainCTAChoices);
      let choiceLabelsArr = allChoices.map(
        (choice) => choice.in_choice_text_content
      );
      const payload = {
        partId: this.part.part_id,
        engagementId: engagement.engagement_id,
        eventType: eventType,
        selctedChoiceLabel: selectedChoiceLabel,
        choiceLabels: choiceLabelsArr,
        btnClick: 1,
        timeSpentInSeconds: 0,
        videoPercentageWatched: 0,
      };
      store.dispatch("createPartEngagementEvent", payload);
    },
    showChapter() {
      this.showChapterMode = true;
    },
    closeChapter() {
      this.showChapterMode = false;
    },
    closeVideoContainer() {
      this.handleWatchProgressPatchEventRequest();
      this.disposeVideo();
      this.$router.push({ name: "story-icon" });
    },
    disposeVideo() {
      this.$refs.interactiveVideo.disposeVideo();
    },
    routeToSynccServicePage() {
      const serviceUrl = "https://syncc.unstack.website/";
      window.open(serviceUrl, "_blank", "noopener");
    },
  },
};
</script>


<style scoped>
.syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
  zoom: 100%;
}
.syncc-widget__zoom-content-horizontal--storyvideo-SDe3pxn1 {
  zoom: 100%;
}
@-moz-document url-prefix() {
  /* For firefox */
  .syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
    transform-origin: 0 0;
    transform: scale(1);
  }
}

@media screen and (max-width: 767px) {
  .syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
    zoom: 96%;
  }
  .syncc-widget__zoom-content-horizontal--storyvideo-SDe3pxn1 {
    zoom: 56%;
  }
  @-moz-document url-prefix() {
    /* For firefox */
    .syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
      transform-origin: 0 0;
      transform: scale(0.96);
    }
    .syncc-widget__zoom-content-horizontal--storyvideo-SDe3pxn1 {
      transform-origin: 0 0;
      transform: scale(0.56);
    }
  }
}
.syncc-widget__video-K8eklU93N {
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
  object-fit: cover;
  width: 640px !important;
  height: 360px;
  z-index: 996 !important;
  cursor: pointer;
}
.syncc-widget__on-video-overlay-container-lN3AAd82 {
  position: absolute;
  width: 100%;
  bottom: 0.3rem !important;
  left: 0 !important;
  align-items: center;
  text-align: center;
}
.syncc-widget__video-message-text-9dlPcmss {
  font-size: 1.4rem;
  margin-bottom: 0.9rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  z-index: 999 !important;
}
.syncc-widget__choices-container-on-video-Nre77iuc {
  z-index: 997 !important;
  -webkit-box-pack: center;
  -webkit-box-align: stretch;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 15px;
}
.syncc-widget__choices-container-on-video-horizontal-dm21NOwcc {
  display: flex;
  flex-flow: row wrap;
}
.syncc-widget__choices-container-on-video-vertical-dm21NOwcc {
  white-space: normal;
  width: 100%;
  max-width: 100%;
  justify-items: stretch;
  margin-left: 25px;
  margin-right: 25px;
}
.syncc-widget__video-close-btn-Ake8ck3 {
  position: absolute;
  top: 5px !important;
  right: 4px !important;
  z-index: 998 !important;
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>