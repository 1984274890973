<template>
  <div class="syncc-widget__video-loading-K8eklU93N" :style="containerStyle">
    <img class="syncc-widget__video-loading-img-2OnBFLZ7" :src="loader" />
    <div
      style="
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000 !important;
      "
      @click="emitRouteToSynccServicePage"
    >
      <img :src="logo" style="max-width: 95px" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containerPosition: {
      type: Object,
      required: true,
    },
    videoOrientationStyle: {
      type: Object,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loader: "",
      containerStyle: {},
    };
  },
  mounted() {},
  created() {
    this.containerStyle = {
      ...this.containerPosition,
      ...this.videoOrientationStyle,
    };
    const isProduction = process.env.NODE_ENV === "production";
    if (isProduction) {
      this.loader = "https://syncc-widget.web.app/img/loader_black.gif";
    } else {
      this.loader = require("@/assets/gif/loader_black.gif");
    }
  },
  methods: {
    emitRouteToSynccServicePage() {
      this.$emit("route-to-syncc-service-page");
    },
  },
};
</script>


<style scoped>
.syncc-widget__video-loading-K8eklU93N {
  display: block;
  position: fixed;
  -webkit-user-select: none;
  /* this is what centers your element in the fixed wrapper*/
  display: flex;
  flex-flow: column nowrap;
  justify-content: center; /* aligns on vertical for column */
  align-items: center; /* aligns on horizontal for column */
  border-radius: 0.852rem;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(251, 251, 251, 0.978);
  transition: background-color 300ms;
  object-fit: cover;
  z-index: 999 !important;
  cursor: pointer;
}
.syncc-widget__video-loading-img-2OnBFLZ7 {
  width: 25%;
}
</style>