<template>
  <mux-video
    id="synccPreloadVideo"
    slot="media"
    preload="auto"
    :playback-id="muxSrc"
    metadata-video-title="Sync widget video preload"
    metadata-viewer-user-id="user-id-1234"
    stream-type="on-demand"
    prefer-mse
    playsinline
    muted
    @canplay="preloadDone"
    style="width: 1px; height: 1px; display: none"
  ></mux-video>
</template>


<script>
import "@mux/mux-video";

export default {
  props: {
    playbackId: { type: String, required: true },
    jwt: { type: String, required: true },
  },
  data() {
    return {
      muxSrc: String,
      videoEl: null,
    };
  },
  created() {
    this.muxSrc = this.playbackId + "?token=" + this.jwt;
  },
  mounted() {},
  beforeDestroy() {
    // TODO: パフォーマンスに問題ある場合、以下を利用してください
    // this.disposeVideo();
  },
  methods: {
    preloadDone() {},
  },
};
</script>
