/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

// plugins
// import AtUI from 'at-ui'

// import 'at-ui-style/css/at.css'
// import './assets/css/main.scss'

// widget setup
// (optional) 'Custom elements polyfill'
// import 'document-register-element/build/document-register-element'
import '@ungap/custom-elements';
// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

// Vue.use(AtUI)
Vue.config.productionTip = false

// new Vue is not needed anymore, as you're initializing the app through vue-custom-element
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// use vue-custom-element
App.store = store
App.router = router

Vue.customElement('syncc-widget', App, {
    props: [
        "widget-id",
        "config"
    ],
    shadow: true,
    beforeCreateVueInstance(root) {
        const rootNode = root.el.getRootNode();

        if (rootNode instanceof ShadowRoot) {
            root.shadowRoot = rootNode;
        } else {
            root.shadowRoot = document.head;
        }
        return root;
    },
    shadowCss: `
    /* WidgetIcon */

    .syncc-widget__welcome-icon-close-btn-LdeN298b {
        position: absolute;
        top: 0px !important;
        right: 4px !important;
        z-index: 999 !important;
        cursor: pointer;
    }

    .syncc-widget__welcome-icon-mute-93nxKnwl {
        position: absolute;
        top: 10px !important;
        left: 10px !important;
        z-index: 999 !important;
        cursor: pointer;
    }

    .syncc-widget__welcome-icon-play-video-btn-nl39Nfj2 {
        position: absolute;
        bottom: 16px !important;
        left: 10px !important;
        z-index: 999 !important;
        cursor: pointer;
    }
      
      
    .syncc-widget__notification-badge-circle-6L2doxp {
        position: absolute;
        top: 7px !important;
        right: 7px !important;
        z-index: 998 !important;
        padding: 12px 12px;
        border-radius: 50%;
        background: #ff4c51;
        color: white;
    }
    
    .syncc-widget__notification-badge-rectangle-6L2doxp {
        position: absolute;
        top: -5px !important;
        right: -4px !important;
        z-index: 998 !important;
        padding: 12px 12px;
        border-radius: 50%;
        background: #ff4c51;
        color: white;
    }
    
    .syncc-widget__notification-badge-none-6L2doxp {
        display: none;
    }

    @keyframes syncc-widget__moveup-btn-enter-cDnels34 {
        0% {
            transform: translateY(70px);
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    @keyframes syncc-widget__moveup-btn-leave-lqlK4djd {
        0% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(70px);
            opacity: 0;
        }
    }

    .bottom-enter-active {
        animation: syncc-widget__moveup-btn-enter-cDnels34 !important;
        transform: translate(0px, 0px);
        transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    .bottom-leave-active {
        animation: syncc-widget__moveup-btn-leave-lqlK4djd !important;
        transform: translate(0px, 0px);
        transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
      
    .bottom-enter {
        transform: translateY(100vh) translateY(0px);
    }
    .bottom-leave-to {
        transform: translateY(100vh) translateY(0px);
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    
    .syncc-widget__zoom-content--storyicon-4aqmLwncEr {
       zoom: 100%;
    }

    @-moz-document url-prefix() {
        /* For firefox */
        .syncc-widget__zoom-content--storyicon-4aqmLwncEr {
            transform-origin: 0 0;
            transform: scale(1);
        }
    }
    
    @media screen and (max-width: 767px) {
        .syncc-widget__zoom-content--storyicon-4aqmLwncEr {
            zoom: 52%;
        }
        @-moz-document url-prefix() {
            /* For firefox */
            .syncc-widget__zoom-content--storyicon-4aqmLwncEr {
                transform-origin: 0 0;
                transform: scale(0.45);
            }
        }
    }

    .syncc-widget__icon_hover-Me92Ndxs2 { 
        transition: all 0.3s ease-in-out 0.25s !important;
        visibility: visible !important;
        opacity: 1 !important;
        transform: scale(1.03) !important;
    }

    .syncc-widget__icon_hover-leave-k31lAdn5o { 
        transition: all 0.2s ease-in-out 0.15s !important;
        visibility: visible !important;
        opacity: 1 !important;
        transform: scale(1) !important;
    }

    .syncc-widget__icon-on-scroling-j4k2xDEg { 
        transition: all 0.6s ease-out 0.45s;
        visibility: visible;
        opacity: 1;
        transform: scale(0.88);
    }

    .syncc-widget__icon-on-scroling-37MMepod { 
        transition: all 0.4s ease-out;
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }

    /* StoryVideos */

    .syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
        zoom: 100%;
    }
    
    .syncc-widget__zoom-content-horizontal--storyvideo-SDe3pxn1{
        zoom: 100%;
    }

    @-moz-document url-prefix() {
        /* For firefox */
        .syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
            transform-origin: 0 0;
            transform: scale(1);
        }
    }

    @media screen and (max-width: 767px) {
        .syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
            z-index: 9999 !important;
        }
        .syncc-widget__zoom-content-horizontal--storyvideo-SDe3pxn1 {
            zoom: 56%;
            z-index: 999 !important; 
        }
        @-moz-document url-prefix() {
            /* For firefox */
            .syncc-widget__zoom-content--storyvideo-7bct2Odbwe {
                transform-origin: 0 0;
                transform: scale(0.96);
            }
            .syncc-widget__zoom-content-horizontal--storyvideo-SDe3pxn1 {
                transform-origin: 0 0;
                transform: scale(0.56);
            }
        }
    }

    .syncc-widget__video-K8eklU93N {
        background-color: hsl(0, 0%, 90%);
        transition: background-color 300ms;
        object-fit: cover;
        width: 640px !important;
        height: 360px;
        z-index: 996 !important;
        cursor: pointer;
    }

    .syncc-widget__on-video-overlay-container-lN3AAd82 {
        position: absolute;
        width: 100%;
        bottom: 5px !important;
        left: 0 !important;
        align-items: center;
        text-align: center;
        z-index: 1;
    }

    .syncc-widget__video-message-text-9dlPcmss {
        font-size: 22.5px;
        -webkit-text-size-adjust: auto;
        margin-bottom: 3px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 100%;
        z-index: 999 !important;
        font-family: Arial, sans-serif;
    }

    .syncc-widget__choices-container-on-video-Nre77iuc {
        z-index: 997 !important;
        -webkit-box-pack: center;
        -webkit-box-align: stretch;
        justify-content: center;
        align-items: stretch;
        width: 100%;
        max-width: 800px;
        margin: 0px auto;
        max-height: 300px;
        overflow-y: auto;
        padding-bottom: 22px;
    }
    
    .syncc-widget__choices-container-on-video-horizontal-dm21NOwcc {
        display: flex;
        flex-flow: row wrap;
    }

    .syncc-widget__choices-container-on-video-vertical-dm21NOwcc {
        white-space: normal;
        width: 100%;
        max-width: 100%;
        justify-items: stretch;
    }

    @media screen and (max-width: 767px) {
        .syncc-widget__choices-container-on-video-vertical-dm21NOwcc {
            white-space: normal;
            width: 100%;
            max-width: 100%;
            justify-items: stretch;
        }    
    }

    
    .syncc-widget__video-close-btn-Ake8ck3 {
        position: absolute;
        top: 5px !important;
        right: 4px !important;
        z-index: 998 !important;
        cursor: pointer;
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }


    /* ThanksPage */

    .syncc-widget__thanks-page-action-btn-31VkOEcn {
        width: 44.8px;
        height: 44.8px;
        border-radius: 50%;
        border: none;
        background-color: #2f3137;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Arial, sans-serif;
    }
      
    .syncc-widget__thanks-container-Brdoe29d {
        position: fixed;
        -webkit-user-select: none;
        border-radius: 13.6px;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        letter-spacing: 0.0892857143em;
        background-color: rgba(20, 20, 20, 0.99);
        transition: background-color 300ms;
        object-fit: cover;
        z-index: 999 !important;
        align-items: center;
        font-family: Arial, sans-serif;
    }
    
    .syncc-widget__zoom-content--thanks-cYec1Lwe4 {
        zoom: 100%;
    }

    .syncc-widget__zoom-content-horizontal--thanks-7oOexnqx4 {
        zoom: 100%;
    }
    
    @-moz-document url-prefix() {
        /* For firefox */
        .syncc-widget__zoom-content--thanks-cYec1Lwe4 {
            transform-origin: 0 0;
            transform: scale(1);
        }
    }
    
    @media screen and (max-width: 767px) {
        .syncc-widget__zoom-content--thanks-cYec1Lwe4 {
            zoom: 73%;
            z-index: 999 !important;
            max-height: 80% !important;
        }
        .syncc-widget__zoom-content-horizontal--thanks-7oOexnqx4 {
            zoom: 56%;
        }
        @-moz-document url-prefix() {
            /* For firefox */
            .syncc-widget__zoom-content--thanks-cYec1Lwe4 {
                transform-origin: 0 0;
                transform: scale(0.96);
            }
            .syncc-widget__zoom-content-horizontal--thanks-7oOexnqx4 {
                transform-origin: 0 0;
                transform: scale(0.56);
            }
        }
    }

    .syncc-widget__video-close-btn-Ake8ck3 {
        position: absolute;
        top: 5px !important;
        right: 4px !important;
        z-index: 998 !important;
        cursor: pointer;
    }


    /* VideoContainerCloseBtn */

    .syncc-widget__flatbutton-universal-n1Bxlwrr {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: #2f3137;
        display: flex;
        cursor: pointer;
        z-index: 998 !important;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Arial, sans-serif;
    }


    /* InteractionVideo.vue */

    .mux-video__horizontal::part(video) {
        object-fit: cover;
        border-radius: 13.6px;
        width: 640px !important;
        height: 360px;
    }
    
    .mux-video__vertical::part(video) {
        object-fit: cover;
        border-radius: 13.6px;
        width: 360px !important;
        height: 640px;
    }
    
    @media screen and (max-width: 767px) {
        .mux-video__vertical::part(video) {
            object-fit: cover;
            border-radius: 13.6px 13.6px 0 0;
            width: 100vw !important;
            height: 86vh !important;
        }
    }


    .syncc-widget__interactive-video-container-ml238skcl {
        border-radius: 13.6px;
    }
    
    .syncc-widget__media-controller-Q18nSj38d {
        border-radius: 13.6px;
        background-color: #ffffff00;
    }

    .syncc-widget__media-controller-bar-cm98dowldss {
        position: absolute;
        top: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-family: Arial, sans-serif;
    }

    .disable-background > * {
        background: none !important;
    }

    .media-controller__custom-button {
        border: none;
        -webkit-user-select: none;
        line-height: 0;
        --media-loading-icon-width: 44px;
        -webkit-box-direction: normal;
        display: inline-block;
        height: auto;
        width: auto;
        box-sizing: border-box;
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        transition: background 0.15s linear;
        pointer-events: auto;
        cursor: pointer;
        font-family: Arial, sans-serif;
        vertical-align: middle;
        background: none !important;
    }
    
    .media-control-bar-time-range__horizontal-initial {
        width: 490px !important;
    }

    .media-control-bar-time-range__horizontal-noninitial {
        width: 455px !important;
    }
    
    @media screen and (max-width: 767px) {
        .media-control-bar-time-range__horizontal-initial {
            width: 490px !important;
        }
        .media-control-bar-time-range__horizontal-noninitial {
            width: 455px !important;
        }
    }
    
    .media-control-bar-time-range__vertical-initial {
        width: 270px !important;
    }

    .media-control-bar-time-range__vertical-noninitial {
        width: 230px !important;
    }

    @media screen and (max-width: 767px) {
        .media-control-bar-time-range__vertical-initial {
            width: 270px !important;
        }
        .media-control-bar-time-range__vertical-noninitial {
            width: 230px !important;
        }
    }
    

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
         opacity: 0;
    }

    .syncc-widget__gradient-top-9Ndo12djx {
        border-radius: 13.6px;
        transition: opacity 0.25s;
        padding-top: 37px;
        position: absolute;
        width: 100%;
        height: 80px;
        top: 0;
        pointer-events: none;
        background-position: top;
        background-repeat: repeat-x;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACqCAYAAABsziWkAAAAAXNSR0IArs4c6QAAAQVJREFUOE9lyNdHBQAAhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5jsP56Xz8PM5gcC/xfDEmjhKxEOCSaREEiSbFEqkQppJpzJMJiWyINvkUCIX8kw+JQqg0BRRxaaEEqVQZsopUQGVpooS1VBjglStqaNEPTSYRko0QbNpoUQrtJl2qsN0UqILuk0PJXqhz/RTYgAGzRA1bEYoMQpjZpwSExAyk5SYgmkzQ82aOUqEIWKilJiHBbNIiSVYhhVYhTVYhw3YhC3Yhh3YhT3YhwM4hCM4hhM4hTM4hwu4hCu4hhu4hTu4hwd4hCd4hhd4hTd4hw/4hC/4hh/4/QM2/id28uIEJAAAAABJRU5ErkJggg==");
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
    }


    /* InteractiveVideoLoading */

    .syncc-widget__video-loading-K8eklU93N {
        display: block;
        position: fixed;
        -webkit-user-select: none;
        /* this is what centers your element in the fixed wrapper*/
        display: flex;
        flex-flow: column nowrap;
        justify-content: center; /* aligns on vertical for column */
        align-items: center; /* aligns on horizontal for column */
        border-radius: 13.6px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgba(251, 251, 251, 0.978);
        transition: background-color 300ms;
        object-fit: cover;
        z-index: 999 !important;
        cursor: pointer;
    }

    .syncc-widget__video-loading-img-2OnBFLZ7 {
        width: 25%;
    }


    /* Choice */

    .syncc-widget__choice-btn-content-plain-M12ln3sc {
        align-items: center;
        margin: 4px;
        color: #fff;
        display: flex;
        flex: 1 0 auto;
        justify-content: inherit;
        line-height: normal;
        position: relative;
        transition: inherit;
        transition-property: opacity;
        font-family: Arial, sans-serif;
        font-size: 18px;
        font-weight: bold;
        -webkit-text-size-adjust: auto;
    }

    .syncc-widget__choice-btn-content-bold-oO4jsnce {
        align-items: center;
        margin: 4px;
        color: #fff;
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        line-height: normal;
        position: relative;
        transition: inherit;
        transition-property: opacity;
        font-family: Arial, sans-serif;
        font-size: 18px;
        font-weight: bold;
        -webkit-text-size-adjust: auto;
    }


    .syncc-widget__choice-plain-g241kNei {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 0px 12px 0px 16px;
        border-radius: 44px;
        box-sizing: border-box;
        color: white;
        transition: opacity 0.3s ease 0s;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        justify-content: normal;
        min-width: 200px;
        max-width: 300px;
        flex: 1 1 0%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        min-height: 50px;
        height: fit-content;
        font-family: Arial, sans-serif;
    }

    .syncc-widget__choice-image-cta-horizontal-j39Nkdcl {
        cursor: pointer;
        padding: 0px 12px 0px 16px;
        box-sizing: border-box;
        color: white;
        transition: opacity 0.3s ease 0s;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        justify-content: normal;
        max-width: 300px;
        flex: 1 1 0%;
        -webkit-box-align: center;
        align-items: center;
        min-height: 50px;
        height: fit-content;
        font-family: Arial, sans-serif;
    }

    .syncc-widget__choice-image-object-horizontal-l2Kdnwikx {
        object-fit: cover;
        border-radius: 4px;
        height: 50px
    }

    .syncc-widget__choice-image-object-vertical-Lp2mlxpd {
        object-fit: cover;
        border-radius: 4px;
        width: 300px;
        height: 60px;
    }


    .syncc-widget__choice-image-cta-vertical-zn83nksdnk {
        cursor: pointer;
        box-sizing: border-box;
        color: white;
        transition: opacity 0.3s ease 0s;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        justify-content: normal;
        max-width: 300px;
        flex: 1 1 0%;
        -webkit-box-align: center;
        align-items: center;
        min-height: 50px;
        height: fit-content;
        font-family: Arial, sans-serif;
    }


    .syncc-widget__choice-main-cta-0k1Po3nc {
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 0px 12px 0px 16px;
        border-radius: 44px;
        box-sizing: border-box;
        color: white;
        transition: opacity 0.3s ease 0s;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        justify-content: normal;
        min-width: 200px;
        max-width: 300px;
        flex: 1 1 0%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        min-height: 50px;
        height: fit-content;
        font-family: Arial, sans-serif;
    }



    .syncc-widget__chocie-hover-fm3s534Ncle { 
        transition: all 0.2s ease-in-out 0.15s;
        visibility: visible;
        opacity: 1;
        transform: scale(1.03);
    }

    .syncc-widget__choice-hover-leave-L1qcnFrj3 {
        transition: all 0.15s ease-in-out 0.1s;
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }


    /* Chapter */

    .syncc-widget__chapter-container-area-horizontal-n5Nekw2snc {
        position: absolute;
        width: 60%;
        height: 100%;
        top: 0 !important;
        right: 0 !important;
        align-items: center;
        text-align: center;
        z-index: 2;
        border-top-right-radius: 13.6px;
        border-bottom-right-radius: 13.6px;
        background-color: #ffffff
    }

    .syncc-widget__chapter-container-area-vertical-8oLXem9dma {
        position: absolute;
        width: 80%;
        height: 100%;
        top: 0 !important;
        right: 0 !important;
        align-items: center;
        text-align: center;
        z-index: 2;
        border-top-right-radius: 13.6px;
        border-bottom-right-radius: 13.6px;
        background-color: #ffffff
    }

    .right-enter-active, .right-leave-active {
        transform: translate(0px, 0px);
        transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 10ms;
    }
      
    .right-enter, .right-leave-to {
        transform: translateX(100vw) translateX(0px);
    }

    .syncc-widget__chapter-layout-podT3Ruxd {
        margin-left: 3.8rem;
        margin-top: 4.5rem;
    }

    .syncc-widget__chapter-part-21Dcj4Vlero {
        text-align: start;
        margin-top: 1.4rem;
        cursor: pointer;
    }


    .syncc-widget__flatbutton-chapter-iB3kxWE2v5 {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        display: flex;
        cursor: pointer;
        z-index: 999 !important;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Arial, sans-serif;
    }

    /* TextBubble */

    .syncc-widget__text-bubble-8N3klwN {
        position: fixed;
        max-width: 600px;
        background-color: #fff;
        padding: 1.125em 1.5em;
        font-size: 20px;
        z-index: 999 !important;
        -webkit-text-size-adjust: auto;
        font-weight: 600;
        color: rgba(37, 37, 37, 0.9);
        letter-spacing: 1px;
        border-radius: 16px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3),
          0 1px 2px rgba(0, 0, 0, 0.2);
        font-family: Arial, sans-serif;
        cursor: pointer;
    }
      
    .syncc-widget__text-bubble-8N3klwN::before {
        position: fixed;
        width: 600px;
        height: 0;
        bottom: 100%;
        left: 1.5em;
        z-index: 999 !important;
        border: 12px solid transparent;
        border-top: none;
        border-bottom-color: #fff;
        filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.1));
        font-family: Arial, sans-serif;
    }
    `
})