<template>
  <div id="syncc-widget__app">
    <async-lazy-main-component
      :widgetId="widgetId"
      :config="JSON.parse(config)"
    />
  </div>
</template>

<script>
import AsyncComponentLoading from "./components/UI/AsyncComponentLoading.vue";
import AsyncComponentErrorLoading from "./components/UI/AsyncComponentErrorLoading.vue";

export default {
  props: ["widget-id", "config"],
  components: {
    AsyncLazyMainComponent: () => ({
      // The component to load (should be a Promise)
      component: import("./views/Main.vue"),
      // A component to use while the async component is loading
      loading: AsyncComponentLoading,
      // A component to use if the load fails
      error: AsyncComponentErrorLoading,
      // Delay before showing the loading component. Default: 200ms.
      delay: 200,
      // The error component will be displayed if a timeout is
      // provided and exceeded. Default: Infinity.
      timeout: 3000,
    }),
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
