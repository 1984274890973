import { Model } from '@vuex-orm/core'

import Video from "./Video"
import Choice from "./Choice"

export default class Part extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'parts'

    static primaryKey = 'part_id'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            part_id: this.attr(''),
            part_meta_id: this.attr(''),
            title: this.attr(''),
            message_text_content: this.attr(''),
            message_text_color_hex: this.attr(''),
            video_response_receivable: this.boolean(false),
            text_response_receivable: this.boolean(false),
            choices_display_timeout: this.attr(0),
            video: this.hasOne(Video, 'part_id'),
            choices: this.hasMany(Choice, 'part_id')
        }
    }
}